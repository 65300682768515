import { Dispatch, SetStateAction } from "react"

import stripAnsi from "strip-ansi"

import {
  IConnectedDevice,
  TSupportedWifiSecurity,
} from "src/components/Install/installTypes"
import { webSerialSend } from "src/components/Install/webSerialUtil"
import { sleep } from "src/utils/genericUtil"
import { debug } from "src/utils/logger"

export function sendCommand(
  s: string,
  outputStream: IConnectedDevice["outputStream"]
) {
  if (!outputStream) {
    debug.log("Error: No outputStream")
    return false
  }
  const cmds: string[] = s.split("&&").map((s) => s.trim())
  cmds.forEach((cmd) => {
    const _cmd = ` ${cmd}` // add leading space to cancel ongoing cmd e.g., 'led blink'
    debug.log(`SENDING '${_cmd}'`)
    webSerialSend(_cmd, outputStream)
    sleep(1)
  })
  return true
}

export function setDeviceWifi(
  ssid: string,
  password: string,
  security: TSupportedWifiSecurity,
  outputStream: IConnectedDevice["outputStream"]
) {
  const cmd = `netcfg set ${ssid} ${security} ${password}`
  return sendCommand(cmd, outputStream)
}

export function enrollDeviceCommand(
  token: string,
  outputStream: IConnectedDevice["outputStream"]
) {
  const cmd = `setup enroll ${token} point.staging.minut.com` // staging?
  return sendCommand(cmd, outputStream)
}

export async function enroll(
  maxEnrollWait: number,
  setHandler: Dispatch<SetStateAction<((s: string) => object) | undefined>>
) {
  let result = { success: false, reason: "" }

  // This handler is buggy and doesn't always get all strings from the
  // device; dunno why.
  setHandler(() => (s: string) => {
    const reason = stripAnsi(s).trim()
    if (s.includes("join failed:")) {
      debug.warn("join failed:", reason)
      result = { success: false, reason }
      return result
    }
    if (s.includes("success")) {
      debug.warn(`success! aborting!`)
      result = { success: true, reason }
      return result
    }
  })

  for (let i = 0; i < maxEnrollWait; i++) {
    debug.log("Result", result.reason)
    if (result.reason) {
      break
    }
    debug.log(`attempting to enroll device ${i}...`)
    await sleep(1000)
  }
  setHandler(undefined) // remove handler
  return result.reason ? result : { success: undefined }
}
