import { useEffect, useState } from "react"

import stripAnsi from "strip-ansi"

import { IConnectedDevice } from "src/components/Install/installTypes"
import { debug, logger } from "src/utils/logger"

export function useReadLoop(
  device: IConnectedDevice | null,
  onDisconnect: () => void
) {
  const [readLoopData, setReadLoopData] = useState<string[]>([])
  const [handler, setHandler] = useState<(s: string) => object>()

  useEffect(() => {
    if (!device) return
    const reader = device.reader
    const outputStream = device.outputStream
    if (!reader || !outputStream) return

    const readLoop = async () => {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read()
        if (value) {
          logger.log(value)
          setReadLoopData((readLoopData) => [
            ...readLoopData,
            stripAnsi(value) + "\n",
          ])
          handler?.(value)
        }
        if (done) {
          setReadLoopData((readLoopData) => [
            ...readLoopData,
            "> Disconnected.",
          ])
          logger.log("> Disconnected.", done)
          reader.releaseLock()
          break
        }
      }
    }
    readLoop().catch((e) => {
      debug.error(e)
      onDisconnect()
    })
    return () => {
      reader && !reader.closed && reader.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, handler])

  return {
    data: readLoopData,
    append: (data: string) => {
      const newData = data.at(-1) === "\n" ? data : data + "\n"
      setReadLoopData((readLoopData) => [...readLoopData, newData])
    },
    clear: () => {
      setReadLoopData([])
    },
    setHandler,
  }
}
