import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MBanner } from "src/ui/MBanner/MBanner"

export function InstallConnectSensor({
  onConnect: deviceConnect,
  loading,
}: {
  onConnect: () => Promise<void>
  loading: boolean
}) {
  return (
    <div>
      <MBanner type="info" fullWidth style={{ marginBottom: "1rem" }}>
        Before proceeding, make sure that
        <ol>
          <li>
            your Minut device is plugged into the computer with a USB cable.
          </li>
          <li>The device is switched ON</li>
        </ol>
      </MBanner>
      <MButtonLegacy
        variant="contained"
        color="primary"
        onClick={deviceConnect}
        loading={loading}
      >
        Connect to sensor
      </MButtonLegacy>
    </div>
  )
}
