import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { Button } from "@material-ui/core"

import { sendCommand } from "src/components/Install/deviceCommands"
import { InstallConsoleCommandList } from "src/components/Install/InstallConsoleCommandList"
import { IConnectedDevice } from "src/components/Install/installTypes"
import { useFlags } from "src/hooks/useFlags"
import { MainContentBox } from "src/ui/Layout/MainContentBox"
import { MTextField } from "src/ui/MTextField/MTextField"
import { debug } from "src/utils/logger"

export function InstallConsole({
  readLoop,
  outputStream,
}: {
  readLoop: { data: string[]; append: (data: string) => void }
  outputStream: IConnectedDevice["outputStream"]
}) {
  const { debug: debugFlag } = useFlags()

  const [command, setCommand] = useState("")
  const [commandHistory, setCommandHistory] = useState<string[]>([])
  const [commandIndex, setCommandIndex] = useState(0)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const logElem = useRef<any>(null)

  function submitCommand(command: string) {
    sendCommand(command, outputStream)
    const newHistory = [...commandHistory, command]
    const newIndex = newHistory.length
    _updateCmd(newIndex, newHistory)
    readLoop.append(`> ${command}`)
    setCommand("")
  }

  function prevCommand() {
    debug.log("cmd prev")
    const newIndex = Math.max(-1, commandIndex - 1)
    _updateCmd(newIndex)
  }

  function nextCommand() {
    debug.log("cmd next")
    const newIndex = Math.min(commandHistory.length, commandIndex + 1)
    _updateCmd(newIndex)
  }

  function _updateCmd(newIndex: number, newHistory?: string[]) {
    const history = newHistory || commandHistory
    const cmd = history[newIndex] || ""
    debug.log("cmd", cmd, "index", newIndex, history)
    setCommandIndex(newIndex)
    setCommand(cmd)
    newHistory && setCommandHistory(newHistory)
  }

  function addCommand(command: string) {
    const newHistory = [...commandHistory, command]
    const newIndex = commandHistory.length
    _updateCmd(newIndex, newHistory)
  }

  useEffect(() => {
    if (!readLoop.data.length) return
    const log = logElem?.current
    if (log) {
      log.textContent = readLoop.data.join("")
      log.scrollTop = log.scrollHeight
    }
  }, [readLoop, readLoop.data.length])

  return (
    <MainContentBox>
      <LogBox>
        <LogOutput ref={logElem} />

        <InstallConsoleCommandList
          sendCommand={submitCommand}
          setCommand={addCommand}
          hidden={!debugFlag}
        />

        <InputBox hidden={!debugFlag}>
          <MTextField
            style={{ flexGrow: 1 }}
            label="Send command"
            placeholder="help"
            value={command}
            onChange={(value) => setCommand(value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                debug.log("enter")
                submitCommand(command)
              } else if (e.key === "ArrowUp") {
                prevCommand()
              } else if (e.key === "ArrowDown") {
                nextCommand()
              }
            }}
          />
          <Button onClick={() => submitCommand(command)}>SEND</Button>
        </InputBox>
      </LogBox>
    </MainContentBox>
  )
}

const LogOutput = styled.pre`
  height: 30ch;
  padding: 1rem;
  overflow: auto;
  color: greenyellow;
  background: black;
  font-family: monospace;
  margin-bottom: 1rem;
`

const LogBox = styled.div`
  margin-top: 2rem;
  max-width: 1000px;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 1em 5px;
  &[open] {
    padding: 0.5em;
    & summary {
      border-bottom: 1px solid #aaa;
      margin-bottom: 0.5em;
    }
  }
  & > summary {
    font-weight: bold;
    margin: -0.5em -0.5em 0;
    padding: 0.5em;
    &:focus {
      outline: unset;
    }
    cursor: pointer;
  }
`

const InputBox = styled.div`
  display: flex;
  align-items: center;
`
