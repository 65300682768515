/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react"
import styled from "styled-components"

import {
  enroll,
  enrollDeviceCommand,
  setDeviceWifi,
} from "src/components/Install/deviceCommands"
import { InstallConnectSensor } from "src/components/Install/InstallConnectSensor"
import { InstallConsole } from "src/components/Install/InstallConsole"
import { InstallDeviceForm } from "src/components/Install/InstallDeviceForm"
import {
  IConnectedDevice,
  IWifiCredentials,
} from "src/components/Install/installTypes"
import { useReadLoop } from "src/components/Install/useReadLoop"
import {
  webSerialConnect,
  webSerialDisconnect,
} from "src/components/Install/webSerialUtil"
import { API_DEFAULT } from "src/constants/minutApi"
import { DEFAULT_HOME_NAME, THome } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useFlags } from "src/hooks/useFlags"
import { Routes } from "src/router/routes"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MainView } from "src/ui/Layout/MainView"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"
import { debug, logger } from "src/utils/logger"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

const MAX_ENROLL_TIME = 20

// XXX: These are redux stubs, which will render the install inoperable; it will
// be replaced with a proper context soon.
const setDeviceAction = (payload: IConnectedDevice | null) => {}
const dispatch = (a: any) => (a: any) => {}
const useSelector = (a: any): any => {}
const getSerialDevice = (state: any) => {
  return state.serialDevice
}

export function InstallDevice() {
  // XXX: If use has no homes, show redirect to create home page + show toast message about it
  const { debug: debugMode } = useFlags()
  const device: IConnectedDevice | null = useSelector(getSerialDevice) // XXX: Keep global state in appdata instead of redux
  const [error, setError] = useState<{
    type: "error" | "warning" | "good" | "info"
    message: React.ReactNode
  }>()
  useEffect(() => {
    if (!device) {
      setError(undefined)
    }
  }, [device])

  const [deviceInstalling, setDeviceInstalling] = useState(false)
  const [deviceConnecting, setDeviceConnecting] = useState(false)
  const user = useGetUser()

  const readLoop = useReadLoop(device, () => deviceDisconnect())

  async function handleInstall({
    home,
    wifiOffice,
    wifiHome,
  }: {
    home: THome
    wifiOffice: IWifiCredentials
    wifiHome?: IWifiCredentials
  }) {
    setError(undefined)
    if (!device || !device.outputStream || !device.reader) {
      setError({ type: "error", message: "No connected device." })
      return
    }
    setDeviceInstalling(true)
    setDeviceWifi(
      wifiOffice.ssid,
      wifiOffice.password,
      wifiOffice.security,
      device.outputStream
    )
    await handleEnroll(
      user.user_id,
      home.home_id,
      home.name || DEFAULT_HOME_NAME(home.home_id)
    )
    if (wifiHome) {
      debug.log("Setting device home wifi", wifiHome)
      setDeviceWifi(
        wifiHome.ssid,
        wifiHome.password,
        wifiHome.security,
        device.outputStream
      )
    }
    setDeviceInstalling(false)
  }

  async function handleEnroll(
    user_id: string,
    home_id: string,
    homeName: string
  ) {
    if (!device?.outputStream || !device.reader) {
      return
    }
    if (!home_id || !user_id) {
      debug.warn({ home_id, user_id })
      return
    }

    const { data } = await minutApiHttpClient.post(
      `${API_DEFAULT}/applications/enroll`,
      {
        user_id,
        home_id,
      }
    )
    const token = data?.enroll_token

    if (!token) {
      logger.error(data)
      setError({
        message: "Could not enroll device: token not found",
        type: "error",
      })
      return false
    }

    enrollDeviceCommand(token, device.outputStream)
    readLoop.setHandler(undefined)
    const enrollResult = await enroll(MAX_ENROLL_TIME, readLoop.setHandler)

    debug.log(enrollResult)
    if (enrollResult.success === false) {
      setError({ message: enrollResult.reason, type: "error" })
    } else if (enrollResult.success === true) {
      setError({
        message: (
          <>
            Successfully installed device in{" "}
            <InternalLink to={Routes.Home.location(home_id)}>
              {homeName}
            </InternalLink>
          </>
        ),
        type: "info",
      })
    } else if (enrollResult.success === undefined) {
      setError({ message: "Enrolling device timed out", type: "warning" })
    }
  }

  async function deviceConnect() {
    debug.log("connect")
    setDeviceConnecting(true)
    try {
      const device = await webSerialConnect()
      dispatch(setDeviceAction(device))
    } catch (e: any) {
      if (e.constructor === DOMException) {
        logger.log(e)
      } else {
        throw e
      }
    } finally {
      setDeviceConnecting(false)
    }
  }

  async function deviceDisconnect() {
    debug.log("disconnect")
    setDeviceConnecting(true)
    if (!device) return
    try {
      await webSerialDisconnect(device)
    } catch (e) {
      debug.warn(e)
    } finally {
      dispatch(setDeviceAction(null))
      setDeviceConnecting(false)
    }
  }

  const tabs: ITab[] = [
    {
      id: "install",
      label: "Install",
      view: !!device ? (
        <InstallDeviceForm
          onInstall={handleInstall}
          loading={deviceInstalling}
        />
      ) : (
        <InstallConnectSensor
          loading={deviceConnecting}
          onConnect={deviceConnect}
        />
      ),
    },
    {
      id: "configure",
      label: "Configure Wifi",
      view: <div>To be implemented...</div>,
      hidden: !debugMode,
    },
    {
      id: "console",
      label: "Device Console",
      view: (
        <InstallConsole
          readLoop={readLoop}
          outputStream={device?.outputStream ?? null}
        />
      ),
      hidden: !device,
    },
  ].filter((t) => !t.hidden)

  return (
    <MainView
      title={
        <div style={{ display: "inline-flex", gap: "1rem" }}>
          <span>Install Minut</span>
          <MButtonLegacy
            onClick={deviceDisconnect}
            emergency
            hidden={!device}
            loading={deviceConnecting}
          >
            Disconnect
          </MButtonLegacy>
        </div>
      }
    >
      {error && <MBanner type={error.type}>{error.message}</MBanner>}

      <Tabs labels={tabs}>
        {tabs.map((t, i) => (
          <StyledTabPanel key={i}>{t.view}</StyledTabPanel>
        ))}
      </Tabs>
    </MainView>
  )
}

const StyledTabPanel = styled(TabPanel)`
  margin: 1rem 0;
`
