import { useState } from "react"

import { MenuItem } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"

export function InstallConsoleCommandList({
  sendCommand,
  setCommand,
  hidden,
}: {
  sendCommand: (command: string) => void
  setCommand: (command: string) => void
  hidden?: boolean
}) {
  const [selectCommand, setSelectCommand] = useState<string>("")
  // const [, setCommand] = useState<string>()

  return (
    <FormControl
      variant="outlined"
      style={{ width: "100%", marginBottom: "10px" }}
      hidden={hidden}
    >
      <InputLabel id="command-list-label">Commands</InputLabel>
      <Select
        labelId="command-list-label"
        id="command-list"
        label="Commands"
        // native // Disabled for demo purposes
        value={selectCommand}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
        onChange={(e: any) => {
          if (!e.target.value) return
          const inp: string = e.target.value
          setSelectCommand(inp)
          if (inp[0] === "#") {
            const s: string = e.target.value.replace(/#\w?/, "")
            setCommand(s)
            return // deferred command, manual input
          } else {
            sendCommand(inp)
          }
        }}
        inputProps={{
          name: "cmd-preset",
          id: "cmd-preset",
        }}
      >
        <MenuItem value={`help`}>Help</MenuItem>
        <MenuItem value={`led blink`}>Blink</MenuItem>
        <MenuItem value={`led color 1 1 255`}>Evil glow</MenuItem>
        <MenuItem value={`playbeep 100 500 1000 20000 10000`}>
          Play beep
        </MenuItem>
        <MenuItem
          value={`led color 1 255 1 && playbeep 50 300 500 25000 10000`}
        >
          Dubstep Mode
        </MenuItem>
        <MenuItem value={`tss inject EVENT PASSIVE_INFRARED 10`}>
          Inject motion event
        </MenuItem>
        <MenuItem value={`set alarm_grace_vol 10`}>
          Grace period sound: Off
        </MenuItem>
        <MenuItem value={`set alarm_grace_vol 1000`}>
          Grace period sound: On
        </MenuItem>
        <MenuItem value={`app_event do_heartbeat`}>Force heartbeat</MenuItem>
        <MenuItem
          value={`set alarm_siren_start_hz 1 && set alarm_siren_end_hz 1`}
        >
          Alarm sound: OFF
        </MenuItem>
        <MenuItem value={`# netcfg set {ssid} {security} {password}`}>
          Set device wifi
        </MenuItem>
        <MenuItem disabled></MenuItem>
      </Select>
    </FormControl>
  )
}
